import type { ComponentType } from 'react'

import { Builder } from '@builder.io/react'
import type { Component } from '@builder.io/sdk/dist/src/builder.class'

export const DEFAULT_BUILDER_IMAGE =
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F6bef27ee40d24f3b88239fd7e616f82a'

export type BuilderRegisterComponentOptions = Component

export function registerBuilderComponent(
    component: ComponentType<any>,
    options: BuilderRegisterComponentOptions,
) {
    Builder.registerComponent(component, options)
}

import { joinBenefitsRegistration } from './components/Benefits'
import { buttonRegistration } from './components/Button'
import { footerRegistration } from './components/Footer'
import { highlightTextRegistration } from './components/HighlightText'
import { joinButtonRegistration } from './components/JoinButton'
import { joinNavBarRegistration } from './components/JoinNavBar'
import { joinPlansRegistration } from './components/Plans'
import { joinPricingRegistration } from './components/Pricing'
import { joinReviewsRegistration } from './components/Reviews'
import { tagCloudRegistration } from './components/TagCloud'
import { valuePropRegistration } from './components/ValueProp'
import { registerBuilderComponent } from './registerBuilderComponent'

// Register components here
registerBuilderComponent(buttonRegistration.component, buttonRegistration.options)
registerBuilderComponent(joinBenefitsRegistration.component, joinBenefitsRegistration.options)
registerBuilderComponent(joinPlansRegistration.component, joinPlansRegistration.options)
registerBuilderComponent(joinReviewsRegistration.component, joinReviewsRegistration.options)
registerBuilderComponent(footerRegistration.component, footerRegistration.options)
registerBuilderComponent(joinNavBarRegistration.component, joinNavBarRegistration.options)
registerBuilderComponent(joinPricingRegistration.component, joinPricingRegistration.options)
registerBuilderComponent(joinButtonRegistration.component, joinButtonRegistration.options)
registerBuilderComponent(highlightTextRegistration.component, highlightTextRegistration.options)
registerBuilderComponent(tagCloudRegistration.component, tagCloudRegistration.options)
registerBuilderComponent(valuePropRegistration.component, valuePropRegistration.options)

import styled from 'styled-components'

import HighlightedText from './Highlight'

import ScrollCarousel from 'components/Carousel/ScrollCarousel'
import Image from 'components/Image'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import Earth from 'public/images/earth.png'
import FinanceUp from 'public/images/finance-up.png'
import Find from 'public/images/find.png'
import OnThePulse from 'public/images/on-the-pulse.png'
import Slide1 from 'public/images/value-prop-slide-1.png'
import Slide2 from 'public/images/value-prop-slide-2.png'
import Slide3 from 'public/images/value-prop-slide-3.png'
import Slide4 from 'public/images/value-prop-slide-4.png'
import type { ThemedComponent } from 'theme/Types'

export type ValueSlideContent = {
    titleLineOne: string
    titleLineTwo: string
    body: string
}

type ValueProps = {
    slide1?: ValueSlideContent
    slide2?: ValueSlideContent
    slide3?: ValueSlideContent
    slide4?: ValueSlideContent
}

export const defaultSlideContent: Required<ValueProps> = {
    slide1: {
        titleLineOne: 'GET NEW',
        titleLineTwo: 'INVESTING IDEAS',
        body: 'Find your next big investment opportunity before anyone else from our world-class financial analysts.',
    },
    slide2: {
        titleLineOne: 'MASTER THE MARKETS',
        titleLineTwo: 'IN MINUTES',
        body: 'We analyze stories and trends across the world and bring you the market news that matters most, as it breaks in real time.',
    },
    slide3: {
        titleLineOne: 'LEARN',
        titleLineTwo: 'EVERYWHERE',
        body: 'Get fresh investing insights wherever you are, however you like it. Finimize is there for you on web, app, email and can be listened to in our App, and on Spotify or Apple Podcasts.',
    },
    slide4: {
        titleLineOne: 'SAVE TIME',
        titleLineTwo: '',
        body: 'We turn hours of investment research into minutes, giving you back time to do the other things in life you love besides investing.',
    },
}

const slideImages = [
    {
        backgroundImage: Slide1,
        foregroundImage: FinanceUp,
    },
    {
        backgroundImage: Slide2,
        foregroundImage: OnThePulse,
    },
    {
        backgroundImage: Slide3,
        foregroundImage: Earth,
    },
    {
        backgroundImage: Slide4,
        foregroundImage: Find,
    },
]

const ValuePropCarousel = ({
    slide1 = defaultSlideContent.slide1,
    slide2 = defaultSlideContent.slide2,
    slide3 = defaultSlideContent.slide3,
    slide4 = defaultSlideContent.slide4,
}: ValueProps) => {
    const slides = [slide1, slide2, slide3, slide4].map((slide, index) => ({
        ...slide,
        ...slideImages[index],
    }))

    return (
        <ScrollCarousel ariaLabel="Value Proposition Carousel">
            {slides.map((slide, index) => (
                <Slide key={index} backgroundImage={slide.backgroundImage.src}>
                    <Box flex="1" display="flex" justifyContent="center">
                        <ImageDiskContainer>
                            <Image
                                src={slide.foregroundImage}
                                alt={slide.titleLineOne + ' ' + slide.titleLineTwo}
                            />
                        </ImageDiskContainer>
                    </Box>
                    <Box flex="1" display="flex" flexDirection="column" alignItems="center">
                        <TitleContainer>
                            <HighlightedText size="extraSmall">
                                {slide.titleLineOne || ''}
                            </HighlightedText>
                            <HighlightedText size="extraSmall">
                                {slide.titleLineTwo || ''}
                            </HighlightedText>
                        </TitleContainer>

                        <Content>{slide.body}</Content>
                    </Box>
                </Slide>
            ))}
        </ScrollCarousel>
    )
}

type StyledComponent = ThemedComponent<{}>

const ImageDiskContainer = styled.div<StyledComponent>`
    width: 135px;
    height: 135px;
    padding: 35px;
    background-color: ${({ theme }) => theme.palette.neutral[999]};
    border-radius: 50%;
    margin: auto;
`

const Slide = styled.div<{ backgroundImage: string }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    background-position: center;
    width: 270px;
    height: 450px;
    padding: 1rem;
`

const TitleContainer = styled(Box)<StyledComponent>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    height: 6rem;
`

const Content = styled(Paragraph)<StyledComponent>`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.XS};
    color: ${({ theme }) => theme.palette.neutral[50]};
`

export default ValuePropCarousel

import dynamic from 'next/dynamic'

import {
    DEFAULT_BUILDER_IMAGE,
    type BuilderRegisterComponentOptions,
} from '../registerBuilderComponent'

const component = dynamic(() => import('modules/Footer/Footer'))

const options: BuilderRegisterComponentOptions = {
    name: 'Footer',
    image: DEFAULT_BUILDER_IMAGE,
}

export const footerRegistration = { component, options }

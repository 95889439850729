import dynamic from 'next/dynamic'

import {
    DEFAULT_BUILDER_IMAGE,
    type BuilderRegisterComponentOptions,
} from '../registerBuilderComponent'

const component = dynamic(() => import('components/TagCloud'))

const options: BuilderRegisterComponentOptions = {
    name: 'Tag Cloud',
    inputs: [
        {
            name: 'tags',
            type: 'list',
            subFields: [
                {
                    name: 'tag',
                    type: 'string',
                },
            ],
        },
    ],
    image: DEFAULT_BUILDER_IMAGE,
}

export const tagCloudRegistration = { component, options }

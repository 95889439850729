import styled from 'styled-components'

import type { FontSizes, ThemedComponent } from 'theme'
import { fonts } from 'theme'

export type HighlightSize = 'extraSmall' | 'small' | 'medium' | 'large'

const highlightSizes = {
    extraSmall: fonts.size.L,
    small: fonts.size.XL,
    medium: fonts.size.XXL,
    large: fonts.size.XXXXXL,
}

type HighlightProps = {
    children: string
    size?: HighlightSize
    fullWidth?: boolean
}

const HighlightedText = ({ children, size = 'large', fullWidth = false }: HighlightProps) => {
    return (
        <SlantedHighlightText $fontSize={highlightSizes[size]} $fullWidth={fullWidth}>
            {children}
        </SlantedHighlightText>
    )
}

const SlantedHighlightText = styled.div<
    ThemedComponent<{ $fontSize: FontSizes; $fullWidth: boolean }>
>`
    display: inline-block;
    padding: 0 0.4rem;
    background-color: ${({ theme }) => theme.palette.highlight[500]};
    color: black;
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
    font-size: ${({ $fontSize }) => $fontSize};
    transform: skew(-5deg) rotate(-3deg);

    white-space: nowrap;
    word-wrap: break-word;
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
`

export default HighlightedText

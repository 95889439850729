import dynamic from 'next/dynamic'

import {
    DEFAULT_BUILDER_IMAGE,
    type BuilderRegisterComponentOptions,
} from '../registerBuilderComponent'

const component = dynamic(() => import('containers/Join/sections/builder/JoinNavBar'))

const options: BuilderRegisterComponentOptions = {
    name: 'Join Nav Bar',
    image: DEFAULT_BUILDER_IMAGE,
}

export const joinNavBarRegistration = { component, options }

import dynamic from 'next/dynamic'

import {
    DEFAULT_BUILDER_IMAGE,
    type BuilderRegisterComponentOptions,
} from '../registerBuilderComponent'

import type { HighlightSize } from 'containers/Join/sections/builder/Highlight'

const highlightSizes: Array<{ label: string; value: HighlightSize }> = [
    { label: 'Small', value: 'small' },
    { label: 'Medium', value: 'medium' },
    { label: 'Large', value: 'large' },
]

const component = dynamic(() => import('containers/Join/sections/builder/Highlight'))

const options: BuilderRegisterComponentOptions = {
    name: 'Highlight Text',
    inputs: [
        { name: 'children', type: 'text' },
        {
            name: 'size',
            type: 'enum',
            enum: highlightSizes.map(size => ({
                label: size.label,
                value: size.value,
            })),
        },
    ],
    image: DEFAULT_BUILDER_IMAGE,
}

export const highlightTextRegistration = { component, options }

import dynamic from 'next/dynamic'

import {
    DEFAULT_BUILDER_IMAGE,
    type BuilderRegisterComponentOptions,
} from '../registerBuilderComponent'

import type { ButtonVariant } from 'containers/Join/sections/builder/JoinButton'

const buttonVariants: Array<{ label: string; value: ButtonVariant }> = [
    { label: 'Primary', value: 'primary' },
    { label: 'Secondary', value: 'secondary' },
]

const component = dynamic(() => import('containers/Join/sections/builder/JoinButton'))

const options: BuilderRegisterComponentOptions = {
    name: 'Join Button',
    inputs: [
        {
            name: 'variant',
            type: 'enum',
            enum: buttonVariants.map(variant => ({
                label: variant.label,
                value: variant.value,
            })),
        },
        { name: 'text', type: 'text' },
    ],
    image: DEFAULT_BUILDER_IMAGE,
}

export const joinButtonRegistration = { component, options }

import dynamic from 'next/dynamic'

import {
    DEFAULT_BUILDER_IMAGE,
    type BuilderRegisterComponentOptions,
} from '../registerBuilderComponent'

const component = dynamic(() => import('containers/Join/sections/Plans'))

const options: BuilderRegisterComponentOptions = {
    name: 'Join Plans',
    image: DEFAULT_BUILDER_IMAGE,
}

export const joinPlansRegistration = { component, options }

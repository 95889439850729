import dynamic from 'next/dynamic'

import {
    DEFAULT_BUILDER_IMAGE,
    type BuilderRegisterComponentOptions,
} from '../registerBuilderComponent'

import {
    defaultSlideContent,
    type ValueSlideContent,
} from 'containers/Join/sections/builder/ValueProp'

const component = dynamic(() => import('containers/Join/sections/builder/ValueProp'))

const createSlideField = (name: string, defaultValue: ValueSlideContent) => ({
    name,
    type: 'object',
    defaultValue,
    subFields: [
        {
            name: 'titleLineOne',
            type: 'string',
            required: true,
        },
        {
            name: 'titleLineTwo',
            type: 'string',
        },
        {
            name: 'body',
            type: 'string',
            required: true,
        },
    ],
})

const options: BuilderRegisterComponentOptions = {
    name: 'Join Value Prop',
    inputs: [
        createSlideField('slide1', defaultSlideContent.slide1),
        createSlideField('slide2', defaultSlideContent.slide2),
        createSlideField('slide3', defaultSlideContent.slide3),
        createSlideField('slide4', defaultSlideContent.slide4),
    ],
    image: DEFAULT_BUILDER_IMAGE,
}

export const valuePropRegistration = { component, options }

import { useEffect, useState } from 'react'

import { BuilderComponent as BComponent } from '@builder.io/react'
import type { BuilderContent } from '@builder.io/sdk'
import '@builder.io/widgets/dist/lib/builder-widgets-async'

import BuilderLoader from './BuilderLoader'

import builder from 'modules/Builder/config'
import Error from 'pages/_error'

type Props = {
    modelName: string
    data?: BComponent['data']
    context?: BComponent['context']
    fallbackComponent: React.ReactElement | null
}

import 'modules/Builder/BuilderRegistry'

const ERROR_STATE = undefined

const BuilderComponent = ({ modelName, data, context, fallbackComponent }: Props) => {
    const [builderContentJson, setBuilderContentJson] = useState<
        BuilderContent | null | typeof ERROR_STATE
    >(null)

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const content = await builder
                    .get(modelName, { url: window.location.pathname })
                    .promise()
                setBuilderContentJson(content)
            } catch (error) {
                console.error('Error fetching content from Builder.io:', error)
                setBuilderContentJson(ERROR_STATE)
            }
        }

        fetchContent()
    }, [modelName])

    if (builderContentJson === ERROR_STATE) return fallbackComponent || <Error />

    if (!builderContentJson) return <BuilderLoader />

    return (
        <BComponent model={modelName} content={builderContentJson} data={data} context={context} />
    )
}

export default BuilderComponent

import Box from 'components/Primitives/Box'
import { Spinner } from 'components/Spinner'

const BuilderLoader = () => (
    <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
        <Spinner />
    </Box>
)

export default BuilderLoader

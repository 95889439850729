import dynamic from 'next/dynamic'

import {
    DEFAULT_BUILDER_IMAGE,
    type BuilderRegisterComponentOptions,
} from '../registerBuilderComponent'

const component = dynamic(() => import('containers/Join/sections/builder/BuilderPricing'))

const options: BuilderRegisterComponentOptions = {
    name: 'Join Pricing',
    image: DEFAULT_BUILDER_IMAGE,
    inputs: [
        {
            name: 'pricing',
            type: 'object',
        },
    ],
}

export const joinPricingRegistration = { component, options }
